/* hero section */
.hero--section {
  width: 100%;
  height: 700px;
  background-repeat: no-repeat!important;
  background-size: cover!important;
}
.hero--content{
  padding: 100px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #00000099;
}
.catchy--phrase{
  width: 70%;
}
.home--page_heading{
  margin-top: 0;
  color: var(--secondary);
  font-size: 3rem;
}
.home--page_heading>span{
  color: var(--accent);

}
.catchy--phrase h3{
  color:var(--secondary-derv)
}
@media (max-width: 768px) {
.hero--section {
height: 400px;
}
  
  .hero--content {
    padding: 30px;
  }

  .catchy--phrase {
    width: 95%;
  }

  .home--page_heading {
    font-size: 2rem;
  }
.catchy--phrase h3{
font-size: 1rem;
}
}
@media (max-width: 480px) {
.catchy--phrase h3{
margin-top: 0;
}
.catchy--phrase h1{
  margin-bottom: 0;
  }
  .catchy--phrase {
  width: 100%;
  }
  .hero--content {
    padding: 10px;
  }

}
/* hero section end */

/* home page main content section */

.home--page_content{
  width:100%;
  padding: 50px 10px;
}
