.input-container {
	width: 70%;
	position: relative;
}

.icon {
	position: absolute;
	right: 10px;
	top: calc(50% + 5px);
	transform: translateY(calc(-50% - 5px));
	border: none;
	background-color: transparent;
}

.search--input {
	width: 100%;
	border-radius: 2%;
	height: 40px;
	padding: 10px;
	transition: .2s linear;
	border :none;
	border-top: 2.5px solid var(--black);
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 2px;
  }
  
  .search--input:focus {
	outline: none;
	border: 0.5px solid var(--primary);
	box-shadow: -5px -5px 0px var(--primary);
  }
  
  .input-container:hover > .icon {
	animation: anim 1s linear infinite;
  }
  
  @keyframes anim {
	0%,
	100% {
	  transform: translateY(calc(-50% - 5px)) scale(1);
	}
  
	50% {
	  transform: translateY(calc(-50% - 5px)) scale(1.1);
	}
  }