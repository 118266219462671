
*{
  font-family: 'Nunito', sans-serif;
}
/* :root{
  --primary:#c02a2c;
  --primary-derv:#5e2e2f;
  --secondary:#f7f6f5;
  --secondary-derv:#dbf7cc;
  --accent:#5cc436;
  --accent-derv:#668e50;
  --black:#2d2d2e;
  
} */
/* :root{
  --primary:#328b3c;
  --primary-derv:#75a758;
  --secondary:#f9f3ed;
  --secondary-derv:#dbf7cc;
  --accent:#8b2e2f;
  --accent-derv:#5e2e2f;
  --black:#2d2d2e;
  
} */
:root {
  --primary: #297d4b;        
  --primary-derv: #2d533d;   
  --secondary: #FFFFFF;      
  --secondary-derv: #F2F2F2; 
  --accent: #cc0000;         
  --accent-derv: #ff5e5e;    
  --black: #333333;          
}


body{

  background-color: var(--secondary);
}
/* 
main{
  margin: auto;

  max-width: 1350px;
} */
.card a{
  text-decoration: none;
}