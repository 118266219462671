.card--listing_container{
  width: 100%; 
}
.card--listing {
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  max-width: 100%;
  column-gap: calc(33% - 360px);
  row-gap: 20px;
}
@media (max-width:1200px){
.card--listing {
  grid-template-columns: repeat(3,1fr);
  column-gap: calc(50% - 405px);

}

}
@media (max-width:900px){
  .card--listing {
    grid-template-columns: repeat(2,1fr);
    column-gap: calc(33% - 540px);
    justify-items: center;
  }
  
  }
  @media (max-width:600px){
    .card--listing {
      grid-template-columns: repeat(1,1fr);
      column-gap: 0;
    }
    
    }
.card--listing_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card--listing_footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* @media (max-width:979px){
    .card--listing{
    grid-template-columns: repeat(3, 1fr);

    }
}
@media (max-width:768px){
    .card--listing{
    grid-template-columns: repeat(2, 1fr);

    }
} */
@media (max-width: 480px) {
  .card--listing_title {
    justify-content: center;
    flex-direction: column;
  }
  /* .card--listing{
    grid-template-columns: repeat(1, 1fr);
    } */
}

