ul {
  margin: 0;
  list-style: none;
}

.restaurant {
  position: relative;
  width: 260px;
  height: 400px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 20px;
  transition: all .3s ease-in-out;
}
.restaurant:hover{
  transform: scale(1.1);
  transition: all .3s ease-in-out;

}

.restaurant .infos_left {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  float: left;
  background: var(--accent);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.restaurant .reservation_right {
  position: absolute;
  left: 70%;
  z-index: 1;
  width: 0%;
  height: 100%;
  float: right;
  background: white;
  /* overflow-x: scroll; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: width 0.5s;
}

.restaurant .reservation_right--active {
  overflow: hidden;
  width: 30%;
}

.restaurant .infos_left .image_container {
  width: 100%;
  height: 70%;
  background-size: cover;
  background-position: center;
  transition: background-size 0.5s;
}


.restaurant .infos_left .prix_block {
  position: absolute;
  top: 10px;
  left: -10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: var(--primary);
  padding: 5px 10px;
  box-shadow: -6px 0px 20px rgba(0, 0, 0, 0.1);
}

.restaurant .infos_left .prix_block .text_prix {
  font-size: 15px;
  color: var(--secondary-derv);
}

.restaurant .infos_left .prix_block .prix {
  font-size: 18px;
  color: var(--primary);
}

.restaurant .infos_left .block_infos {
  width: 100%;
  height: 40%;
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0px -6px 20px 0px rgb(0 0 0 / 10%);
  transition: height 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.restaurant .infos_left .block_infos h1 {
  float: left;
  text-transform: uppercase;
  text-align: left;
  font-size: 18px;
  margin: 10px 10px;
  color: var(--primary);
}

.restaurant .infos_left .block_infos .adress_block {
  text-align: right;
  margin: 0px 5px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
}

.restaurant .infos_left .block_infos .adress_block svg {
  margin-right: 5px;
  font-size: 20px;
}

.restaurant .infos_left .block_infos .adress_block .adress {
  font-size: 12px;
  text-overflow: ellipsis;
  max-width: 270px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
  overflow: hidden;
  text-align: left;
  }

  
  .restaurant .infos_left .block_infos .adress_block .locate {
	  background: var(--primary);
	  color: white;
	  padding: 2px 10px;
	  border-radius: 5px;
	  
	}
.restaurant .infos_left .block_infos .description_block p {
  text-align: justify;
  padding: 0px 10px;
  color: rgba(0, 0, 0, 0.8);
  max-height: 72px;
  text-overflow: ellipsis;
  width: 260px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  overflow: hidden;
}

.ui-datepicker-inline {
  display: block;
  width: 100%;
  height: auto;
  margin-left: -5px;
  border: none !important;
}

.ui-datepicker table {
  font-size: 11px;
}

.ui-datepicker-header {
  font-size: 10px;
  background: var(--primary);
  color: white;
}

.ui-state-default.ui-state-active {
  background: var(--primary) !important;
  color: white !important;
}

.ui-state-default.ui-state-highlight {
  border: 2px solid var(--primary) !important;
}

.ui-state-default {
  background: white !important;
  border: none !important;
  text-align: center !important;
}
