.event__card {
  padding: 10px;
  max-width: 260px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(99, 160, 18, 0.06);
  background-color: rgb(255, 255, 255);
  font-family: sans-serif;
  transition: all .3s ease-in-out;
}
.event__card:hover{
  transform: scale(1.1);
  transition: all .3s ease-in-out;
}
.event__info__loc .adress{
    text-overflow: ellipsis;
    width: 240px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.event__content {
  margin-top: 1rem;
}

.event__content .event__header {
  margin-top: 0.5rem;
}

.event__card .event__image {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  background-color: rgb(205, 235, 255);
  padding: 9px;
}

.event__card .event__tag {
  text-transform: capitalize;
  background-color: rgba(126, 206, 22, 0.425);
  padding: 2px 8px;
  border-radius: 4px;
  color: rgb(2, 2, 2);
  /* color: var(--primary-derv); */
}

.event__card .event__date {
  display: flex;
  font-size: 0.75rem;
  line-height: 1rem;
  align-items: center;
  margin: 2px;
}

.event__card .event__title {
  display: block;
  color: var(--primary);
  font-size: 1.25rem;
  line-height: 1.35rem;
  font-weight: 600;
  padding-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.event__card  .event__description {
  line-height: 1.375;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
  overflow: hidden;
  color: black;
}

:link {
  font-style: normal;
}
.event__card a {
  text-decoration: none;
}

.event__card .adress_block  {
  color: var(--primary);
  padding: 2px 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  
}
.event__card .event__info__loc{
  display: flex !important;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7%;
}


.event__card .status-orange {
  background-color: orange;
  color: white;
}

.event__card .status-green {
  background-color: green;
  color: white;
}

.event__card .status-red {
  background-color: red;
  color: white;
}

.event__card .status-yellow {
  background-color: yellow;
  color: black;
}