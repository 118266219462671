.footer--container {
  width: 100%;
  bottom: 0;
  background-color: var(--primary);
  padding: 100px 0 30px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 13px;
  line-height: 20px;
}
.footer--container .row {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  color: var(--secondary);
}
.footer--container .col {
  flex-basis: 25%;
  padding: 10px;
}

.footer--container .logo-footer {
  width: 75px;
  margin-bottom: 30px;
}
.footer--container .col h3 {
  width: fit-content;
  margin-bottom: 40px;
  position: relative;
  color: var(--secondary);
}
.footer--container .email-id {
  width: fit-content;
  border-bottom: 1px solid var(--secondary);
  color: var(--secondary);
  margin: 20px 0;
}
.footer--container ul {
  padding: 0;
}
.footer--container ul li {
  list-style: none;
  margin-bottom: 12px;
}
.footer--container ul li a {
  text-decoration: none;
  color: var(--secondary);
}
.footer--container .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer--container .col {
  flex-basis: calc(25% - 20px);
}
.footer--container form {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary);
  margin-bottom: 50px;
}
.footer--container form > .far {
  font-size: 18px;
  margin-right: 10px;
}
.footer--container form input {
  width: 100%;
  background: transparent;
  color: var(--secondary);
  border: 0;
  outline: none;
}
.footer--container form button {
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.footer--container form button .fas {
  font-size: 16px;
  color: var(--secondary);
}
.footer--container .social-media-icon-footer .social--footer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: var(--secondary);
  margin-right: 15px;
  cursor: pointer;
}

@keyframes moving {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}
.footer--container .col:nth-child(2),
.footer--container .col:nth-child(3) {
  flex-basis: 15%;
}
.footer--container .col:nth-child(2) p,
.footer--container .col:nth-child(3) p {
  text-align: left;
}
@media (max-width: 700px) {
  .footer--container {
    bottom: unset;
  }
  .footer--container .col {
    flex-basis: 100%;
  }
  .footer--container .col:nth-child(2),
  .footer--container .col:nth-child(3) {
    flex-basis: 95%;
  }
}
@media (max-width: 200px) {
.footer--container .col {
  padding: 0;
}
.footer--container .row{
  width:98%
}

}