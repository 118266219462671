.card--listing_container2{
width: 100%;
}

.card--listing_title2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.card--listing_footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* @media (max-width:979px){
    .card--listing{
    grid-template-columns: repeat(3, 1fr);

    }
}
@media (max-width:768px){
    .card--listing{
    grid-template-columns: repeat(2, 1fr);

    }
} */
@media (max-width: 480px) {
  .card--listing_title {
    justify-content: center;
    flex-direction: column;
  }
  /* .card--listing{
    grid-template-columns: repeat(1, 1fr);
    } */
}
