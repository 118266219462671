/* blogPost.css */

/* Define CSS variables */
:root {
  /* --primary: #ff6b6b; */
  /* --secondary: #4ecdc4; */
  --background: #f7fff7;
  --text: #333;
}

.article {
  margin: 20px;
  padding: 20px;
  background-color: var(--background);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header__title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 10px;
}

.article__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog__header__tag {
  display: inline-block;
  margin-right: 5px;
  padding: 5px 10px;
  background-color: var(--accent);
  color: var(--secondary);
  border-radius: 4px;
  font-size: 14px;
}

.text-block {
  text-align: justify;
}
.text-block h2{
margin-top: 0;
}
.rich-text p {
  margin-bottom: 10px;
  color: var(--text);
}
/* blogPost.css */

/* ... (existing CSS styles) */

.blog__header {
  width: 100%;
  display: flex;
  align-items:flex-start;
  justify-content: space-around;
  margin-bottom: 20px;
}

.blog__header img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  height: auto;
  margin-right: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.header__title {
  font-size: 28px;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 10px;
}
