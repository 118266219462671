.event-wrapper {
  padding: 40px 9%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  min-height:70vh;
}

.event-social {
  grid-column: 1/3;
}

.event-content {
  grid-column: 3/10;
}

.event-img img {
  border-radius: 3px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}

.social-link {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 3px;
  background-color: var(--primary-derv);
}
.social-link > a {
  width: calc(100% - 50px);
  text-align: center;

  color: white;
  text-decoration: none;
}

.social-link svg {
  background-color: var(--accent);
  padding: 5px;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 3px;
}

.event-title {
  color:var(--black);
  margin-top: 0;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 20px;
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  margin: 50px 0;
}
.event-grid>*:first-child{
  border-right: 3px solid grey;
}

.event-grid-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.event-grid-item>svg{
  margin-bottom:20px;
  font-weight: 300;
}
.event-tags {
  margin: 20px 0;
}

.event-tag {
  display: inline-block;
  padding: 6px 12px;
  background-color: #eaeaea;
  color: #333;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 10px;
}

.event-tag:last-child {
  margin-right: 0;
}


.event-grid-item svg {
  fill: black;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.event-grid-item div {
  font-size: 14px;
}

.event-content-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.event-to-remember-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.event-to-remember-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.event-to-remember-item div:first-child {
  margin-right: 10px;
}
