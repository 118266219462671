.success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background-color: #f5f5f5;
  }
  
  .success-page h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .success-page p {
    font-size: 1.5rem;
    color: #777;
    margin-bottom: 2rem;
  }
  