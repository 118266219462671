.blog--listing-container{
  display: flex;
    flex-flow: column nowrap;
    align-items: center;
    place-content: space-between flex-start;
    padding: 50px 9%;
}

.cards-mapper {
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  max-width: 100%;
  column-gap: calc(33% - 400px);
  row-gap: 20px;
}
@media (max-width:1200px){
  .cards-mapper {
    grid-template-columns: repeat(3,1fr);
    column-gap: calc(50% - 405px);
  
  }
  
  }
  @media (max-width:900px){
    .cards-mapper {
      grid-template-columns: repeat(2,1fr);
      column-gap: calc(33% - 540px);
      justify-items: center;
    }
    
    }
    @media (max-width:600px){
      .cards-mapper {
        grid-template-columns: repeat(1,1fr);
        column-gap: 0;
      }
      
      }
.add-listing_wrapper h1{
    font-size: 2.5rem;
    font-family: 'Montserrat';
    font-weight: normal;
    color: #444;
    text-align: center;
    margin: 2rem 0;
  }
  
  .add-listing_wrapper{
    width: 90%;
    margin: 0 auto;
    max-width: 80rem;
  }
  
  .add-listing_wrapper .cols{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .add-listing_wrapper .col{
    width: calc(25% - 2rem);
    margin: 1rem;
    cursor: pointer;
  }
  
  .add-listing_wrapper .container{
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
      -webkit-perspective: 1000px;
              perspective: 1000px;
  }
  
  .add-listing_wrapper .front,
  .add-listing_wrapper .back{
    background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
    border-radius: 10px;
      background-position: center;
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      text-align: center;
      min-height: 280px;
      height: auto;
      border-radius: 10px;
      color: #fff;
      font-size: 1.5rem;
  }
  
  .add-listing_wrapper .back{
    background: #cedce7;
    background: -webkit-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
    background: -o-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
    background: linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  }
  
  .add-listing_wrapper .front:after{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      opacity: .6;
      background-color: #000;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      border-radius: 10px;
  }
  .add-listing_wrapper .container:hover .front,
  .add-listing_wrapper .container:hover .back{
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  }
  
  .add-listing_wrapper .back{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }
  
  .add-listing_wrapper .inner{
      -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
              transform: translateY(-50%) translateZ(60px) scale(0.94);
      top: 50%;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 2rem;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      outline: 1px solid transparent;
      -webkit-perspective: inherit;
              perspective: inherit;
      z-index: 2;
  }
  
  .add-listing_wrapper .container .back{
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
  }
  
  .add-listing_wrapper .container .front{
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
  }
  
  .add-listing_wrapper .container:hover .back{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .add-listing_wrapper .container:hover .front{
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .add-listing_wrapper .front .inner p{
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .add-listing_wrapper .front .inner p:after{
    content: '';
    width: 4rem;
    height: 2px;
    position: absolute;
    background: #C6D4DF;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -.75rem;
  }
  
  .add-listing_wrapper .front .inner span{
    color: rgba(255,255,255,0.7);
    font-family: 'Montserrat';
    font-weight: 300;
  }
  
  @media screen and (max-width: 64rem){
    .add-listing_wrapper .col{
      width: calc(33.333333% - 2rem);
    }
  }
  
  @media screen and (max-width: 48rem){
    .add-listing_wrapper .col{
      width: calc(50% - 2rem);
    }
  }
  
  @media screen and (max-width: 32rem){
    .add-listing_wrapper  .col{
      width: 100%;
      margin: 0 0 2rem 0;
    }
  }